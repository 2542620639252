// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-race-report-js": () => import("./../../../src/pages/race-report.js" /* webpackChunkName: "component---src-pages-race-report-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

